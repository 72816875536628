import React from 'react';
import { Box, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <Box>
      <SectionTitle title="404" />
      <Text textAlign="center" alignSelf="center">
        Sorry this page does not exist!
      </Text>
    </Box>
  </Layout>
);

export default NotFound;
